<template>
  <div v-if="warehouse">
    <div class="row mb-5">
      <span class="display-4 col-6">
        {{ `${warehouse.name}` }} - {{ $t("COMMON.PRODUCTS") }}
      </span>
      <div class="col-6 text-right">
        <base-button
          type="primary"
          icon
          size="sm"
          @click="addWarehouseProduct"
          v-if="$currentUserCan($permissions.PERM_CREATE_PRODUCTS)"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-plus"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("PRODUCTS.ADD_PRODUCT") }}
          </span>
        </base-button>
      </div>
    </div>

    <warehouse-product-list
      :filterWarehouse="warehouse.id"
      :key="renderKey"
      :filterOrganization="warehouse.organization.id"
      @onEditWarehouseProductModal="editWarehouseProduct"
    />
  </div>
</template>
<script>
import requestErrorMixin from "@/mixins/request-error-mixin";
import WarehouseProductList from "@/components/WarehouseProduct/WarehouseProductList.vue";

export default {
  name: "warehouse-view-warehouse-products",

  components: {
    WarehouseProductList,
  },

  props: ["cafeteria"],

  mixins: [requestErrorMixin],

  data() {
    return {
      showWarehouseProductModal: false,
      warehouseProduct: null,
      renderKey: 0,
      formErrors: null,
      loading: false,
      warehouse: null,
    };
  },

  computed: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.cafeteria.warehouse.id;
        await this.$store.dispatch("warehouses/get", id);
        this.warehouse = this.$store.getters["warehouses/warehouse"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
